<template>
  <div>
    <form-wizard
      :color="isBusy ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="isBusy ? 'Saving...' : 'Save'"
      class="mb-3"
      @on-complete="formSubmitted()"
    >
      <!-- faq details tab -->
      <tab-content
        title="FAQ Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="faq-form" class="p-2">
            <b-row>
              <b-col md="6">
                <b-form-group label="Module" label-for="module">
                  <validation-provider
                    #default="{ errors }"
                    name="Module"
                    rules="required"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="data_local.module_id"
                      :options="modules"
                      class="w-100"
                      id="module_id"
                      :reduce="(val) => val.id"
                      label="module_name"
                      placeholder="Select Module.."
                      :clearable="false"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label class="" for="status"> Status </label>
                  <v-select
                    v-model="data_local.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="status_option"
                    :reduce="(val) => val.value"
                    label="label"
                    :clearable="false"
                    :searchable="false"
                    input-id="status"
                    name="status"
                    placeholder="Select Status"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Question" label-for="question">
                  <validation-provider
                    #default="{ errors }"
                    name="Question"
                    rules="required"
                  >
                    <b-form-textarea
                      id="question"
                      name="question"
                      v-model="data_local.question"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Question.."
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Answer" label-for="answer">
                  <validation-provider
                    #default="{ errors }"
                    name="Answer"
                    rules="required"
                  >
                    <b-form-textarea
                      id="answer"
                      name="answer"
                      v-model="data_local.answer"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Answer.."
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" class="">
                <b-form-group label="Attachments" label-for="files">
                  <file-pond
                    ref="pond"
                    name="files[]"
                    max-file-size="5MB"
                    max-files="10"
                    allow-multiple="true"
                    v-bind:files="myFiles"
                    image-preview-height="120px"
                    accepted-file-types="image/*,application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    file-validate-type-label-expected-types="Expects a PDF, DOC or DOCX file"
                  />
                  <small class="text-warning flex items-center">
                    File size should be less than 5MB and allowed formats are
                  </small>
                  <p>( .doc, .docx, .pdf, image)</p>
                </b-form-group>
              </b-col>

              <b-form-group
                v-for="(item, index) in customFields"
                :key="item.id"
                class="col-md-6"
              >
                <!-- #default="{ errors }" -->
                <validation-provider
                  :name="item.name"
                  rules=""
                  #default="validationContext"
                >
                  <b-form-group
                    v-if="item.type == 'number'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="number"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'text'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="text"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'url'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="url"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'rating'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <div class="d-flex align-items-center">
                      <star-rating
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :show-rating="false"
                        :star-size="30"
                      ></star-rating>
                      <span
                        v-if="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :class="`rating-btn btn ${
                          ratingdescription[
                            data_local.get_customfields_data['field_' + item.id]
                          ].class
                        }`"
                      >
                        {{
                          ratingdescription[
                            data_local.get_customfields_data["field_" + item.id]
                          ].text
                        }}</span
                      >
                      <input
                        type="hidden"
                        v-model="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                      />
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'password'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-input
                      :id="item.name"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :placeholder="item.label"
                      type="password"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>

                  <b-form-group
                    v-if="item.type == 'textarea'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <b-form-textarea
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      trim
                      :placeholder="item.label"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.type == 'date'"
                    :label="item.label"
                    label-for="input-1"
                  >
                    <flat-pickr
                      :config="{ dateFormat: 'Y-m-d' }"
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                      :value="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      class="form-control"
                      placeholder="Select Date.."
                    />
                  </b-form-group>

                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import faqStoreModule from "../faqStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    FilePond,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      isBusy: false,
      redirectFaq: "",
      data_local: {
        module_id: "",
        status: "1",
        get_customfields_data: [],
      },
      customFields: [],
      modules: [],
      status_option: [],
      myFiles: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-faqs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, faqStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
  },

  methods: {
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("faq-form"));

      formData.append("module_id", self.data_local.module_id);
      formData.append("status", self.data_local.status);

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`attachments[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "faqs";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this faq to NHR
          this.isBusy = false;
          this.$router.push("/faq-list");
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Faq Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Faq Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Faq Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Faq Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-faqs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.modules = res.data.modules;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
          component: ToastificationContent,
          props: {
            title: `Oops! Custom Fields Getting Failed`,
            icon:'BellIcon',
            variant : 'danger',
            hideClose: true,
            
          },},{
          timeout : 3000,
          position : 'bottom-center',
          toastClassName:"error-info",
          hideProgressBar : true,
              })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";

.filepond--credits {
  display: none;
}
</style>

<style lang="scss" scoped>
.assign-interview-btn {
  position: absolute;
  right: 180px;
  bottom: 60px;
  border: solid 1px;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
